import React from 'react';

import config from '../../config';

export default function Footer() {
  const styles = {
    color: 'rgba(255, 195, 0, 0.6)',
  };

  return (
    <header id="header">
      <div style={{ border: '1px solid', padding: '30px' }}>
        <h1 style={styles}>{config.heading}</h1>
        <p style={styles}>{config.subHeading} &trade;</p>
      </div>
      <p style={{ paddingTop: '40px' }}>site under construction</p>
    </header>
  );
}
