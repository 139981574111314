module.exports = {
  siteTitle: 'Barley', // <title>
  manifestName: 'Eventually',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/logo.png',
  pathPrefix: `/gatsby-starter-eventually/`, // This path is subpath of your hosting https://domain/portfolio
  // social
  heading: 'Barley-Benincasa Lab & Studio',
  subHeading: 'text made new',
  socialLinks: [
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/barley-benincasa',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/TextMadeNew',
    },

    // {
    //   icon: 'fa-envelope-o',
    //   name: 'Email',
    //   url: 'mailto:manthewriter@gmail.com',
    // },
  ],
};
